.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3%;
}

.footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 45px;
    line-height: 1.2;
}

.footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: min(10%, 6rem);
    cursor: pointer;
}

.footer-btn p {
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 1.2;
    color: #fff;
    word-spacing: 2px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.footer-links div {
    width: 250px;
    margin: 1rem;
}


.footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.footer-links_div h4 {
    font-size: 14px;
    line-height: 1.2;
    font-family: var(--font-family);
    color: #fff;
    margin-bottom: min(2%, 1rem);
}

.footer-links_div p {
    font-size: 12px;
    line-height: 1.2;
    font-family: var(--font-family);
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-copyright {
    margin-top: min(1%, 1rem);
    margin-bottom: -1rem;
    text-align: center;
    width: 100%;
}

.footer-copyright p {
    font-size: 10px;
    font-family: var(--font-family);
    color: #fff;
}

@media screen and (max-width: 850px) {
    .footer-heading h1 {
        font-size: 44px;
    }
}

@media screen and (max-width: 550px) {
    .footer-heading h1 {
        font-size: 30px;
    }

    .footer-links div {
        margin: 1rem 0;
    }

    .footer-btn p {
        font-size: 14px;
    }
}

@media screen and (max-width: 400px) {
    .footer-heading h1 {
        font-size: 24px;
    }
}
